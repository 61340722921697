import GameCard from "../smallGameCard"
import { Link } from "gatsby"
import React from "react"

import Imge from "../../assets/images/team-logo-1.png"
import Style from "./gameCategoryWrapper.module.scss"
import { useState,useEffect,useRef } from "react"
function GameCategoryWrapper({ category, data, setref,viewAll, gameLink }) {
  const categoryCap = category[0].toUpperCase() + category.slice(1)
  const showData = viewAll ? data : data.slice(0, 6)
  const [map, setMap] = useState(null)
  // const setref = useRef(null)

  // useEffect(() => {
  //   if (setref) {
  //    console.log(setref)
  //   }
  // }, [setref])

console.log(setref)
  return (
    <div     className={Style.mainContainer}>
      <div className={Style.gameWrapperHeading}>

        <div className={Style.heading}>

          <img src={Imge}  />
          <h2 className={Style.categHeading}>{categoryCap}</h2>

          <div className={Style.matchinfo}>
          {data.length > 6 && (
            <span className={Style.gameCount}>{`${data.length} games`} </span>
          )}
          {!viewAll && data.length > 6 && (
            <Link
              to={`/${category.toLowerCase().replace(/[^a-zA-Z0-9]/g, "-")}/`}
              className={Style.viewAll}
            >
              View All
            </Link>
          )}
        </div>
        </div>
        

      </div>
      <div className={Style.gameCardWrapper}>
        {showData.map((d, i) => (
          <GameCard data={d.node} key={i} gameLink={gameLink} />
        ))}
      </div>
    </div>
  )
}

export default GameCategoryWrapper
