import React from "react"
import GameCategoryWrapper from "../components/gameCategoryWrapper"
import HomeLayout from "../layout/homeLayout"
import SEO from "../components/seo"

function CategoryPage({ pageContext }) {
  return (
    <HomeLayout>
      <SEO
        title={`${pageContext.category} | Gangs of Gamer Free online HTML 5 games`}
      />
      <GameCategoryWrapper
        data={pageContext.data}
        category={pageContext.category}
        viewAll={true}
      />
    </HomeLayout>
  )
}

export default CategoryPage
